import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StringParam, useQueryParam } from 'use-query-params'

import { Accordion } from '@/modules/shared/components/accordion/Accordion'
import { FeedbackModal } from '@/modules/shared/components/feedback-modal/FeedbackModal'
import { HELP_CENTER } from '@/modules/shared/help'
import { GetHelpIcon } from '@/modules/shared/icons/GetHelpIcon'
import SideNavLink from '@/modules/shared/layouts/side-navigation/SideNavLink'
import { SideBarItem } from '@/modules/shared/layouts/side-navigation/purchaser-navigation/PurchaserNavigation'

type HelpCenterSideBarItemProps = Omit<SideBarItem, 'sideBarIndexKey'>

export const HelpCenterSideBarItem = ({
  openAccordionKey,
  changeOpenAccordionKey,
  onCloseDrawer,
}: HelpCenterSideBarItemProps) => {
  const { t } = useTranslation()
  const [showFeedbackModal, setShowFeedbackModal] = useState(false)
  const [liveChatEnabled, setLiveChatEnabled] = useQueryParam('live-chat', StringParam)

  useEffect(() => {
    if (liveChatEnabled === 'true') {
      setTimeout(() => setLiveChatEnabled(null), 1500)
    }
  }, [liveChatEnabled])

  return (
    <>
      <Accordion
        titlePrefixIcon={<GetHelpIcon className="size-7" />}
        title={t('general.helpCenter', 'Help Center')}
        defaultOpen={openAccordionKey === 100}
        handleTriggerClick={() => changeOpenAccordionKey(100)}
        buttonClassName="min-h-11 px-3"
        keepActiveColor
      >
        <SideNavLink
          onCloseDrawer={() => onCloseDrawer()}
          testId="help-center-navigation"
          sideNavLinkItems={[
            {
              key: t('general.openLiveChat', 'Open Live Chat'),
              onClick: () => setLiveChatEnabled('true'),
            },
            {
              key: t('general.viewHelpArticles', 'View Help Articles'),
              target: HELP_CENTER,
              newWindow: true,
            },
            {
              key: t('general.leaveFeedback', 'Leave Feedback'),
              onClick: () => setShowFeedbackModal(true),
            },
          ]}
        />
      </Accordion>
      {showFeedbackModal && (
        <FeedbackModal showModal={showFeedbackModal} onCloseModal={() => setShowFeedbackModal(false)} />
      )}
    </>
  )
}
