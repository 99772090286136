import { useEffect, useRef } from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useScrollToErrorField(formErrors: Record<string, any>) {
  const errorRefs = useRef<Record<string, HTMLDivElement | null>>({})

  useEffect(() => {
    const hasError = Object.values(formErrors).some((error) => !!error)
    if (hasError) {
      const errorKeys = Object.keys(formErrors)
      if (errorKeys.length > 0) {
        errorRefs.current[errorKeys[0]]?.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [formErrors])

  return { errorRefs }
}
