import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const PurchasePlusBaseErrorFragmentDoc = gql`
  fragment PurchasePlusBaseError on BaseError {
    extensions {
      code
      status
    }
    message
  }
` as unknown as DocumentNode<Types.PurchasePlusBaseErrorFragment, unknown>
