import { useQuery } from '@apollo/client'

import { GetPricedCatalogueDocument } from '@/graphql/purchase-plus/generated/getPricedCatalogue.generated'
import { PricedCatalogue } from '@/graphql/purchase-plus/generated/purchasePlus_graphql'
import { PURCHASE_PLUS_GRAPHQL_API } from '@/modules/shared/constants'
import usePricedCatalogId from '@/modules/shared/hooks/params/usePricedCatalogId'
import { checkNetworkStatus } from '@/modules/shared/utils/checkNetworkStatus'

export const useGetPricedCatalogue = () => {
  const pricedCatalogueId = usePricedCatalogId()

  const { data, error, networkStatus, refetch } = useQuery(GetPricedCatalogueDocument, {
    variables: {
      pricedCatalogueId: Number(pricedCatalogueId),
    },
    context: {
      uri: PURCHASE_PLUS_GRAPHQL_API,
    },
    skip: !pricedCatalogueId,
    notifyOnNetworkStatusChange: true,
  })

  const pricedCatalog = data?.pricedCatalogue as PricedCatalogue

  const loadingStates = checkNetworkStatus(networkStatus)

  return {
    refetch,
    pricedCatalog,
    loadingStates,
    error,
  }
}
