import { useQuery } from '@apollo/client'

import { GetUserPagePreferenceDocument } from '@/graphql/purchase-plus/generated/getUserPagePreference.generated'
import useSessionUser from '@/modules/access/hooks/useSessionUser'
import { PURCHASE_PLUS_GRAPHQL_API } from '@/modules/shared/constants'

interface UseGetUserPagePreferenceProps {
  key: string
  userId?: number
  skip?: boolean
}

export function useGetUserPagePreference({ key, userId, skip }: UseGetUserPagePreferenceProps) {
  const { user } = useSessionUser()
  const { data, loading, error } = useQuery(GetUserPagePreferenceDocument, {
    context: {
      uri: PURCHASE_PLUS_GRAPHQL_API,
    },
    variables: {
      userId: userId || Number(user?.id),
      key,
    },
    skip,
  })

  return { pagePreference: data?.user?.pagePreference, loading, error }
}
