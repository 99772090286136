import Bowser from 'bowser'
import { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { UserViewPreferenceEnum } from '@/graphql/purchase-plus/generated/purchasePlus_graphql'
import { ApHubTypeEnum } from '@/graphql/purchasing/generated/purchasing_graphql'
import useSessionUser from '@/modules/access/hooks/useSessionUser'
import { UserAccessControlList } from '@/modules/access/types'
import { getLanguageEnumMapping } from '@/modules/access/utils/getLanguageEnumMapping'
import { userAccessControlListAboutModalMap } from '@/modules/shared/constants'
import { Dates } from '@/modules/shared/types'
import { Env } from '@/modules/shared/types/Env'
// import { findTopMostParentOrg } from '@/modules/shared/utils/findTopMostParentOrg'
import { formatDate } from '@/modules/shared/utils/formatDate'
import { isPurchaser } from '@/modules/shared/utils/isPurchaser'

export type AboutItem = {
  title: string
  value: string | number | null | undefined
  tooltip: string | JSX.Element
}

export default function useAbout() {
  const { t } = useTranslation()

  const {
    userPermissions: currentUserPermissions,
    currentOrganisation,
    currentLegalEntity,
    currentAccountsPayableSystem,
    user: currentUser,
    appVersion,
  } = useSessionUser()
  const browser = Bowser.getParser(window.navigator.userAgent)

  const getEnv = () => {
    const environment = import.meta.env.VITE_APP_BUILD_ENV
    if (environment === Env.Development) return 'Development'
    if (environment === Env.UAT) return 'UAT'
    if (environment === Env.Staging) return 'Staging'
    if (environment === Env.Production) return 'Production'
  }
  const directParentOrg = currentOrganisation?.parent
  // const topMostParentOrg = useMemo(() => {
  //   return findTopMostParentOrg(currentOrganisation?.parent as Purchaser)
  // }, [currentOrganisation])

  const getUserLanguage = () => {
    return getLanguageEnumMapping(currentUser?.applicationPreferences?.defaultLanguage)
  }

  const getUserViewPreference = () => {
    if (currentUser?.applicationPreferences?.viewPreference === UserViewPreferenceEnum.CompactView) {
      return t('general.compact', 'Compact')
    }
    return t('general.regular', 'Regular')
  }

  const yesOrNo = (value: boolean | null | undefined) => {
    return value ? t('general.yes', 'Yes') : t('general.no', 'No')
  }

  const getUserPermissionsMap = () => {
    const permissionsMapping = Object.keys(userAccessControlListAboutModalMap())
      .filter((key) => !key.startsWith(currentOrganisation?.isAPurchaser ? 'SUPPLIER' : 'PURCHASER'))
      .reduce(
        (obj, key) => {
          obj[key as keyof UserAccessControlList] =
            userAccessControlListAboutModalMap()[key as keyof UserAccessControlList]
          return obj
        },
        {} as Record<keyof UserAccessControlList, { title: string; value?: string; tooltip: string }>
      )

    for (const [key, value] of Object.entries(permissionsMapping)) {
      value.value = currentUserPermissions ? yesOrNo(currentUserPermissions[key as keyof UserAccessControlList]) : ''
    }

    return Object.values(permissionsMapping)
  }

  const mapUserPermissions = useMemo(() => getUserPermissionsMap(), [currentUserPermissions])

  const getSessionData = () => {
    return [
      {
        title: t('general.user', 'User'),
        value: currentUser?.fullName,
        tooltip: t('aboutModal.userTooltip', 'The full name of the current user to whom the session belongs.'),
      },
      {
        title: t('general.userID', 'User ID'),
        value: currentUser?.id,
        tooltip: t(
          'aboutModal.userIDTooltip',
          'The unique identifier of the current user to whom the session belongs.'
        ),
      },
      {
        title: t('general.emailAddress', 'Email Address'),
        value: currentUser?.email,
        tooltip: t(
          'aboutModal.emailAddressTooltip',
          'The email address of the current user to whom the session belongs.'
        ),
      },
      {
        title: t('general.organisationUnit', 'Organisation Unit'),
        value: currentOrganisation?.name,
        tooltip: t(
          'aboutModal.organisationUnitTooltip',
          'The name of the Current Organisation Unit that the user is signed into.'
        ),
      },
      {
        title: t('general.organisationUnitID', 'Organisation Unit ID'),
        value: currentOrganisation?.id,
        tooltip: t(
          'aboutModal.organisationUnitIDTooltip',
          'The unique identifier of the Current Organisation Unit that the user is signed into.'
        ),
      },
      {
        title: t('general.organisationType', 'Organisation Type'),
        value: currentOrganisation?.isAPurchaser
          ? t('general.purchaser', 'Purchaser')
          : t('general.supplier', 'Supplier'),
        tooltip: t(
          'aboutModal.organisationTypeTooltip',
          'The name of the Current Organisation Unit that the user is signed into. The type can be either Purchaser or Supplier.'
        ),
      },
      {
        title: t('general.lastSignIn', 'Last Sign In'),
        value: formatDate(Dates.Long, currentUser?.currentSignInAt),
        tooltip: t('aboutModal.lastSignInTooltip', 'The time and date that the user most recently signed in.'),
      },
      {
        title: t('general.device', 'Device'),
        value: browser.getOSName(),
        tooltip: t('aboutModal.deviceTooltip', 'The device being used in the current session.'),
      },
      {
        title: t('general.browser', 'Browser'),
        value: `${browser.getBrowserName()} / ${browser.getBrowserVersion()}`,
        tooltip: t('aboutModal.browserTooltip', 'The browser and browser version being used on the device.'),
      },
      {
        title: t('general.environment', 'Environment'),
        value: getEnv(),
        tooltip: t(
          'aboutModal.environmentTooltip',
          'The environment that the session is taking place in. The environment may be UAT, Staging or Production.'
        ),
      },
      {
        title: t('general.feVersion', 'FE Version'),
        value: appVersion.fe,
        tooltip: t('aboutModal.feVersionTooltip', 'The version number of the PurchasePlus Front-End Application.'),
      },
      {
        title: t('general.beVersion', 'BE Version'),
        value: appVersion.be,
        tooltip: t('aboutModal.beVersionTooltip', 'The version number of the PurchasePlus Back-End Application.'),
      },
    ]
  }

  const getOrganisationDetails = () => {
    return [
      {
        title: t('general.organisationUnit', 'Organisation Unit'),
        value: currentOrganisation?.name,
        tooltip: t(
          'aboutModal.organisationUnitTooltip',
          'The name of the Current Organisation Unit that the user is signed into.'
        ),
      },
      {
        title: t('general.organisationUnitID', 'Organisation Unit ID'),
        value: currentOrganisation?.id,
        tooltip: t(
          'aboutModal.organisationUnitIDTooltip',
          'The unique identifier of the Current Organisation Unit that the user is signed into.'
        ),
      },
      {
        title: t('general.legalEntity', 'Legal Entity'),
        value: currentLegalEntity?.name,
        tooltip: t(
          'aboutModal.legalEntityTooltip',
          'The name of the Current Legal Entity that applies to the Current Organisation Unit.'
        ),
      },
      {
        title: t('general.legalEntityID', 'Legal Entity ID'),
        value: currentLegalEntity?.id,
        tooltip: t('aboutModal.legalEntityIDTooltip', 'The unique identifier of the Current Legal Entity.'),
      },
      {
        title: t('general.directParentOrg', 'Direct Parent'),
        value: directParentOrg?.name || '--',
        tooltip: t(
          'aboutModal.directParentOrgTooltip',
          'The name of the Direct Parent Organisation of the Current Organisation Unit, if any.'
        ),
      },
      {
        title: t('general.directParentOrgID', 'Direct Parent ID'),
        value: directParentOrg?.id || '--',
        tooltip: t('aboutModal.directParentOrgIDTooltip', 'The unique identifier of the Direct Parent Organisation.'),
      },
      // TODO: Add back when topMostParent is added to org
      // {
      //   title: t('general.topMostParent', 'Top Most Parent'),
      //   value: topMostParentOrg?.name || '--',
      //   tooltip: t(
      //     'aboutModal.topMostParentTooltip',
      //     'The name of the Top Most Parent Organisation of the Current Organisation Unit, if any.'
      //   ),
      // },
      // {
      //   title: t('general.topMostParentID', 'Top Most Parent ID'),
      //   value: topMostParentOrg?.id || '--',
      //   tooltip: t('aboutModal.topMostParentIDTooltip', 'The unique identifier of the Top Most Parent Organisation.'),
      // },
      {
        title: t('general.isASupplier', 'Is a Supplier'),
        value: yesOrNo(currentOrganisation?.isASupplier),
        tooltip: t('aboutModal.isASupplierTooltip', 'Indicates if the Current Organisation Unit is a Supplier.'),
      },
      {
        title: t('general.isAPurchaser', 'Is a Purchaser'),
        value: yesOrNo(currentOrganisation?.isAPurchaser),
        tooltip: t('aboutModal.isAPurchaserTooltip', 'Indicates if the Current Organisation Unit is a Purchaser.'),
      },
      ...(currentOrganisation?.isAPurchaser
        ? [
            {
              title: t('apSystem.invoicingEmail.title', 'Paperless Email Address'),
              value: (isPurchaser(currentOrganisation) ? currentOrganisation : {})?.paperlessInvoicingIdentityEmail,
              tooltip: t(
                'apSystem.invoicingEmail.tooltip',
                'The email address that Suppliers can use to send PDF Invoices to this Organisation.'
              ),
            },
            {
              title: t('general.apSystemName', 'AP System Name'),
              value: currentAccountsPayableSystem?.name,
              tooltip: t(
                'aboutModal.apSystemNameTooltip',
                'The name of the AP System that is applied to the Current Organisation Unit.'
              ),
            },
            {
              title: t('general.apSystemID', 'AP System ID'),
              value: currentAccountsPayableSystem?.id,
              tooltip: t(
                'aboutModal.apSystemIDTooltip',
                'The unique identifier of the AP System that is applied to the Current Organisation Unit.'
              ),
            },
            {
              title: t('general.apSystemCurrency', 'AP System Currency'),
              value: currentAccountsPayableSystem?.currency?.code,
              tooltip: t(
                'aboutModal.apSystemCurrencyTooltip',
                'Indicates the currency in use for the Current Organisation Unit. The currency is a property of the AP System that is applied to the Current Organisation Unit.'
              ),
            },
            {
              title: t('general.apSystemType', 'AP System Type'),
              value:
                currentOrganisation?.featureFlags?.apHubType === ApHubTypeEnum.TypeAAutomated
                  ? t('general.TypeA', 'Type A')
                  : t('general.typeB', 'Type B'),
              tooltip: t(
                'aboutModal.apSystemTypeTooltip',
                'Indicates the AP System Type setting applied to the Current Organisation Unit. Type A is used if the Organisation exports Invoices and Credit Notes one at a time, to a preset electronic destination. Type B is used if the Organisation manually creates Export Files via the Export Center, and downloads them or sends them to an electronic destination at their discretion.'
              ),
            },
          ]
        : []),
    ]
  }

  const getUserPermissions = () => {
    return [
      {
        title: t('general.pplusAdministrator', 'P+ Administrator'),
        value: yesOrNo(currentUser?.isAdmin),
        tooltip: t(
          'aboutModal.pplusAdministratorTooltip',
          'Indicates if the user is a PurchasePlus Administrator. A PurchasePlus Administrator is an internal operator of the PurchasePlus system.'
        ),
      },
      ...(mapUserPermissions as AboutItem[]),
    ]
  }

  const GetUserPreferences = () => {
    return [
      {
        title: t('general.language', 'Language'),
        value: getUserLanguage(),
        tooltip: t('aboutModal.languageTooltip', 'Indicates the language preference set for the current user.'),
      },
      {
        title: t('general.viewPreference', 'View Preference'),
        value: getUserViewPreference(),
        tooltip: t(
          'aboutModal.viewPreferenceTooltip',
          'Indicates if the current user has chosen Regular or Compact as a view preference.'
        ),
      },
      {
        title: t('general.expertMode', 'Expert Mode'),
        value: yesOrNo(currentUser?.applicationPreferences?.expertMode),
        tooltip: t('aboutModal.expertModeTooltip', 'Indicates if the current user has enabled Expert Mode.'),
      },
      {
        title: t('general.autoSendPurchaseOrders', 'Auto-Send Purchase Orders'),
        value: yesOrNo(currentUser?.autoSendPurchaseOrder),
        tooltip: (
          <Trans i18nKey="aboutModal.autoSendPurchaseOrdersTooltip">
            Indicates if the current user has Auto-Send Purchase Orders enabled. If enabled, when the current user
            creates and submits a Requisition for approval, Purchase Orders will be automatically marked as Sent when
            the Requisition is finally approved.
          </Trans>
        ),
      },
      {
        title: t('general.defaultOrganisation', 'Default Organisation'),
        value: currentUser?.defaultOrganisation?.name,
        tooltip: t(
          'aboutModal.defaultOrganisationTooltip',
          'Indicates the Organisation that this user will arrive in when they sign in to PurchasePlus.'
        ),
      },
    ]
  }

  const getOrganisationPreferences = () => {
    return [
      {
        title: t('general.homePageStyle', 'Home Page Style'),
        value: currentOrganisation?.featureFlags?.paperlessInvoicingOnly
          ? t('general.paperless', 'Paperless')
          : t('general.regular', 'Regular'),
        tooltip: t(
          'aboutModal.homePageStyleTooltip',
          "Indicates if the user's Current Organisation Unit has a Regular or Paperless Home Page style."
        ),
      },
      {
        title: t('general.showCurrencySymbols', 'Show Currency Symbols'),
        value: yesOrNo(currentOrganisation?.featureFlags?.showCurrencySymbols),
        tooltip: t(
          'aboutModal.showCurrencySymbolsTooltip',
          "Indicates if the user's Current Organisation Unit has Currency Symbols shown or not shown."
        ),
      },
      {
        title: t('general.enableQuickCreate', 'Enable Quick Create'),
        value: yesOrNo(currentOrganisation?.featureFlags?.quickCreate),
        tooltip: t(
          'aboutModal.enableQuickCreateTooltip',
          "Indicates if the user's Current Organisation Unit has the Quick Create feature enabled."
        ),
      },
    ]
  }

  const about = useMemo<Record<string, AboutItem[]>>(
    () => ({
      session: getSessionData(),
      organisationDetails: getOrganisationDetails(),
      userPermissions: getUserPermissions(),
      userPreferences: GetUserPreferences(),
      organisationPreferences: getOrganisationPreferences(),
    }),
    [currentUser, currentOrganisation, currentLegalEntity, appVersion.be, currentAccountsPayableSystem]
  )

  return {
    session: about.session,
    organisationDetails: about.organisationDetails,
    userPermissions: about.userPermissions,
    userPreferences: about.userPreferences,
    organisationPreferences: about.organisationPreferences,
  }
}
