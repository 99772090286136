import { useApolloClient, useMutation } from '@apollo/client'

import { SignOutDocument } from '@/graphql/purchase-plus/generated/signOut.generated'
import { ENTERPRISE_SIGN_IN_PAGE, PURCHASE_PLUS_GRAPHQL_API } from '@/modules/shared/constants'

export default function useSignOut() {
  const apolloClient = useApolloClient()

  const clearCacheAndGoHome = async () => {
    await apolloClient.clearStore()
    window.location.href = ENTERPRISE_SIGN_IN_PAGE
  }

  const mutation = useMutation(SignOutDocument, {
    context: { uri: PURCHASE_PLUS_GRAPHQL_API },
    variables: {
      input: {},
    },
    onCompleted() {
      clearCacheAndGoHome()
    },
    onError() {
      // If mutation fails, still clear cache and navigate to root.
      clearCacheAndGoHome()
    },
  })

  return mutation
}
