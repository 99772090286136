import { useTranslation } from 'react-i18next'

import { DeliveryChargeConfiguration } from '@/graphql/purchasing/generated/purchasing_graphql'
import { TileDetailsTableItem } from '@/modules/shared/components/tile/tile-items/TileDetailsTableItem'
import { useMoney } from '@/modules/shared/hooks/useMoney'
import { classNames } from '@/modules/shared/utils/classNames'

interface DeliveryFeeProps {
  deliveryChargeConfiguration?: Pick<
    DeliveryChargeConfiguration,
    'minimumOrderValueExTax' | 'deliveryChargeExTax' | 'deliveryChargeTaxPercentage'
  >
  isInSupplierDetailsModal?: boolean
}

export function DeliveryFees(props: DeliveryFeeProps) {
  const { t } = useTranslation()
  const { formatMoney } = useMoney()
  const { deliveryChargeConfiguration, isInSupplierDetailsModal = true } = props
  const { minimumOrderValueExTax, deliveryChargeExTax, deliveryChargeTaxPercentage } = deliveryChargeConfiguration || {}
  return (
    <>
      {deliveryChargeConfiguration ? (
        <section className="my-2 border" data-testid="delivery-fees">
          <TileDetailsTableItem
            className={classNames({ 'sm:w-[10rem] sm:min-w-40': !isInSupplierDetailsModal })}
            title={t('shopPage.cart.summary.details.0to', '{{ zero }} - {{ amount }}', {
              zero: formatMoney({ amount: 0 }),
              amount: formatMoney({ amount: minimumOrderValueExTax }),
            })}
            value={`${formatMoney({ amount: deliveryChargeExTax })} ${
              deliveryChargeTaxPercentage
                ? t('shopPage.cart.summary.details.underFeeTax', '(Plus {{ tax }}% Tax)', {
                    tax: deliveryChargeTaxPercentage,
                  })
                : ''
            }`}
            tooltip={t(
              'deliveryFees.0toDescription',
              'The delivery fee for any orders between {{ zero }} and {{ amount }}, excluding tax.',
              { zero: formatMoney({ amount: 0 }), amount: formatMoney({ amount: minimumOrderValueExTax }) }
            )}
          />
          <TileDetailsTableItem
            className={classNames({ 'sm:w-[10rem] sm:min-w-40': !isInSupplierDetailsModal })}
            title={t('shopPage.cart.summary.details.aboveFee', 'Above {{ amount }}', {
              amount: formatMoney({ amount: minimumOrderValueExTax }),
            })}
            value={t('general.freeDelivery', 'Free Delivery')}
            tooltip={t(
              'deliveryFees.aboveDescription',
              'The delivery fee for any orders above {{ amount }}, excluding tax.',
              {
                amount: formatMoney({ amount: minimumOrderValueExTax }),
              }
            )}
          />
        </section>
      ) : (
        <div className="mb-5 mt-2 rounded-md bg-gray-100 p-4 text-center text-sm" data-testid="supplier-details-no-fee">
          {t(
            'shopPage.cart.summary.details.noDeliveryFees',
            'The Supplier has not added any delivery fees to your Organisation in PurchasePlus. Please contact the Supplier using the details above to discuss delivery fees.'
          )}
        </div>
      )}
    </>
  )
}
