import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PurchasePlusPageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasePlus_graphql'

export const GetAccountsPayableSystemSuppliersDocument = gql`
  query GetAccountsPayableSystemSuppliers(
    $after: String
    $before: String
    $first: Int
    $filters: SuppliersQueryFiltersInput
    $last: Int
    $sorts: [SuppliersQuerySortsInput!]
    $accountsPayableSystemId: Int
  ) {
    accountsPayableSystem(id: $accountsPayableSystemId) {
      id
      suppliers(after: $after, before: $before, first: $first, last: $last, sorts: $sorts, filters: $filters) {
        edges {
          node {
            id
            name
            isVerified
            businessNumber
            email
            telephone
            creditApplicationUrl
            supplierRelationship {
              id
              isEnabled
              preferredSupplier
              supplierNumber
              supplierLocationCode
              isSelfManagedCataloguesAllowed
              status
              isEnabledBySupplier
            }
          }
        }
        pageInfo {
          ...PurchasePlusPageInfo
        }
      }
    }
  }
  ${PurchasePlusPageInfoFragmentDoc}
` as unknown as DocumentNode<
  Types.GetAccountsPayableSystemSuppliersQuery,
  Types.GetAccountsPayableSystemSuppliersQueryVariables
>
