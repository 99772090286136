import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const UpdatePreferenceDocument = gql`
  mutation UpdatePreference($input: UpdatePreferenceInput!) {
    updatePreference(input: $input) {
      preference {
        key
        value
      }
    }
  }
` as unknown as DocumentNode<Types.UpdatePreferenceMutation, Types.UpdatePreferenceMutationVariables>
