import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PurchasePlusPageInfoFragmentDoc } from './PageInfo.generated'
import { ProductFragmentDoc } from './Product.generated'
import * as Types from './purchasePlus_graphql'

export const GetProductSubstituteProductsDocument = gql`
  query GetProductSubstituteProducts(
    $productId: Int!
    $filters: SubstituteProductsQueryFiltersInput
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    product(id: $productId) {
      id
      substituteProducts(filters: $filters, after: $after, before: $before, first: $first, last: $last) {
        edges {
          node {
            id
            substitute {
              ...Product
            }
          }
        }
        pageInfo {
          ...PurchasePlusPageInfo
        }
      }
    }
  }
  ${ProductFragmentDoc}
  ${PurchasePlusPageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetProductSubstituteProductsQuery, Types.GetProductSubstituteProductsQueryVariables>
