import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import useSessionUser from '@/modules/access/hooks/useSessionUser'
import useFindMatchRoutes from '@/modules/shared/hooks/useFindMatchRoutes'
import { HomeIcon } from '@/modules/shared/icons/HomeIcon'
import { SideBarContext } from '@/modules/shared/layouts/app-layout/SideBarContext'
import { AccountSideBarItems } from '@/modules/shared/layouts/side-navigation/purchaser-navigation/AccountSideBarItems'
import { InvoicesSideBarItem } from '@/modules/shared/layouts/side-navigation/purchaser-navigation/InvoicesSideBarItem'
import { ProductSideBarItem } from '@/modules/shared/layouts/side-navigation/purchaser-navigation/ProductSideBarItem'
import { ReportsSideBarItem } from '@/modules/shared/layouts/side-navigation/purchaser-navigation/ReportsSideBarItem'
import { SuppliersSideBarItem } from '@/modules/shared/layouts/side-navigation/purchaser-navigation/SuppliersSideBarItem'
import { UsersSideBarItem } from '@/modules/shared/layouts/side-navigation/purchaser-navigation/UsersSideBarItem'
import { classNames } from '@/modules/shared/utils/classNames'

export default function PaperlessNavigation() {
  const sideBar = useContext(SideBarContext)
  const { userPermissions } = useSessionUser()

  const { t } = useTranslation()

  const {
    invoiceRoutesMatch,
    supplierRoutesMatch,
    reportsRoutesMatch,
    myProductRoutesMatch,
    usersRoutesMatch,
    accordionDefaultKey,
    sideBarIndexKeys,
  } = useFindMatchRoutes()

  const [openAccordionKey, setOpenAccordionKey] = useState<number | undefined>()

  const { currentOrganisation } = useSessionUser()
  const { showReportsTab, showInvoicesTab, showProductsTab, showSuppliersTab } = currentOrganisation?.featureFlags || {}

  useEffect(() => {
    if (accordionDefaultKey) {
      setOpenAccordionKey(accordionDefaultKey)
    }
  }, [accordionDefaultKey])

  const changeOpenAccordionKey = (key: number | undefined) => {
    if (key === openAccordionKey) {
      setOpenAccordionKey(undefined)
    } else {
      setOpenAccordionKey(key)
    }
  }

  const onCloseDrawer = () => sideBar?.setDrawerOpen && sideBar.setDrawerOpen(false)

  return (
    <>
      <nav className="flex-1 pb-1 text-sm" data-testid="side-navigation-items">
        <section className="space-y-1.5">
          <NavLink
            data-testid="home-navigation-link"
            to="/dashboard"
            onClick={() => onCloseDrawer()}
            className={({ isActive }) => {
              return classNames(
                'flex h-11 items-center gap-x-2 rounded-md px-3 transition duration-200 hover:brightness-95',
                {
                  'bg-primary text-white': isActive,
                  'bg-gray-200': !isActive,
                }
              )
            }}
          >
            <HomeIcon className="size-7" />
            {t('general.home', 'Home')}
          </NavLink>
          {showInvoicesTab && (
            <InvoicesSideBarItem
              invoiceRoutesMatch={invoiceRoutesMatch}
              openAccordionKey={openAccordionKey}
              changeOpenAccordionKey={changeOpenAccordionKey}
              onCloseDrawer={onCloseDrawer}
              sideBarIndexKey={sideBarIndexKeys.invoiceSideBarKey}
            />
          )}
          {showProductsTab && (
            <ProductSideBarItem
              myProductRoutesMatch={myProductRoutesMatch}
              openAccordionKey={openAccordionKey}
              changeOpenAccordionKey={changeOpenAccordionKey}
              onCloseDrawer={onCloseDrawer}
              sideBarIndexKey={sideBarIndexKeys.myProductSideBarKey}
            />
          )}
          {showSuppliersTab && (
            <SuppliersSideBarItem
              supplierRoutesMatch={supplierRoutesMatch}
              openAccordionKey={openAccordionKey}
              changeOpenAccordionKey={changeOpenAccordionKey}
              onCloseDrawer={onCloseDrawer}
              sideBarIndexKey={sideBarIndexKeys.supplierSideBarKey}
            />
          )}
          {showReportsTab && userPermissions?.PURCHASER_CAN_MANAGE_REPORTS && (
            <ReportsSideBarItem
              reportsRoutesMatch={reportsRoutesMatch}
              openAccordionKey={openAccordionKey}
              changeOpenAccordionKey={changeOpenAccordionKey}
              onCloseDrawer={onCloseDrawer}
              sideBarIndexKey={sideBarIndexKeys.reportsSideBarKey}
            />
          )}
          {(userPermissions?.CAN_VIEW_USERS || userPermissions?.CAN_MANAGE_USERS) && (
            <UsersSideBarItem
              usersRoutesMatch={usersRoutesMatch}
              openAccordionKey={openAccordionKey}
              changeOpenAccordionKey={changeOpenAccordionKey}
              onCloseDrawer={onCloseDrawer}
              sideBarIndexKey={sideBarIndexKeys.usersSideBarKey}
            />
          )}
        </section>
        <AccountSideBarItems
          openAccordionKey={openAccordionKey}
          changeOpenAccordionKey={changeOpenAccordionKey}
          onCloseDrawer={onCloseDrawer}
        />
      </nav>
    </>
  )
}
