import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PurchasingPageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasing_graphql'

export const GetSupplierRelationshipsDocument = gql`
  query GetSupplierRelationships(
    $after: String
    $before: String
    $filter: RansackFilterType
    $first: Int
    $last: Int
    $sort: [RansackSortType!]
  ) {
    currentPurchaser {
      id
      supplierRelationships(after: $after, before: $before, filter: $filter, first: $first, last: $last, sort: $sort) {
        edges {
          node {
            id
            supplierName
            supplierNumber
            supplierId
            isEnabled
            preferredSupplier
            supplier {
              id
              name
              isVerified
              businessNumber
              email
              telephone
              creditApplicationUrl
            }
          }
        }
        pageInfo {
          ...PurchasingPageInfo
        }
      }
    }
  }
  ${PurchasingPageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetSupplierRelationshipsQuery, Types.GetSupplierRelationshipsQueryVariables>
