import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { FlaggingMessageFragmentDoc } from './FlaggingMessage.generated'
import { PurchasingSupplierDetailByLineFragmentDoc } from './SupplierDetailByLine.generated'
import * as Types from './purchasing_graphql'

export const GetInvoiceDocument = gql`
  query GetInvoice($invoiceId: Int) {
    currentPurchaser {
      id
      invoice(id: $invoiceId) {
        id
        invoiceNumber
        createdAt
        inception
        reference
        status
        invoiceDate
        reference
        attachmentUrl
        commentsCount
        hasSuperFlag
        filesCount
        purchaseOrderId
        inception
        isFinanciallyApproved
        isOperationallyApproved
        financiallyApprovable
        operationallyApprovable
        hasLinkedLines
        taxCodeInfo
        taxCodeId
        currency
        customJson
        creator {
          id
          fullName
        }
        financialApprovers {
          edges {
            node {
              id
              createdAt
              user {
                id
                fullName
              }
            }
          }
        }
        operationalApprovers {
          edges {
            node {
              id
              createdAt
              user {
                id
                fullName
              }
            }
          }
        }
        flaggingMessages {
          ...FlaggingMessage
        }
        purchaser {
          id
          name
          accountsPayableSystemId
        }
        organisationUnit {
          id
          name
        }
        receivingDocuments {
          edges {
            node {
              id
              receivingDocumentNumber
              purchaseOrderId
            }
          }
        }
        creditNotes {
          edges {
            node {
              id
              creditNoteNumber
            }
          }
        }
        purchaseOrder {
          id
          purchaseOrderNumber
          currency
          requisition {
            id
            reference
            requisitionNumber
          }
          supplier {
            id
            name
          }
          department {
            id
            name
          }
          account {
            id
            code
          }
          statusEnum
          sentDate
          receivedStatus
          invoicedStatus
          expectedDeliveryDate
          totalValue
        }
        department {
          id
          name
        }
        account {
          id
          code
          accountName
        }
        supplier {
          ...PurchasingSupplierDetailByLine
          supplierRelationship {
            id
          }
        }
        totalValue
        totalTaxValue
        totalValueExTax
        deliveryChargeTax
        deliveryChargeExTax
        supplierNumber
        lineTotal
        lineTaxTotal
        adjustmentExTax
        adjustmentTax
        outOfBalance
      }
    }
  }
  ${FlaggingMessageFragmentDoc}
  ${PurchasingSupplierDetailByLineFragmentDoc}
` as unknown as DocumentNode<Types.GetInvoiceQuery, Types.GetInvoiceQueryVariables>
