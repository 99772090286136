import { useTranslation } from 'react-i18next'

import useSessionUser from '@/modules/access/hooks/useSessionUser'
import { Accordion } from '@/modules/shared/components/accordion/Accordion'
import { InventoryIcon } from '@/modules/shared/icons/InventoryIcon'
import SideNavLink, { SideNavLinkItem } from '@/modules/shared/layouts/side-navigation/SideNavLink'
import { SideBarItem } from '@/modules/shared/layouts/side-navigation/purchaser-navigation/PurchaserNavigation'
import { Themes } from '@/modules/shared/types/Themes'

interface InventorySideBarItemProps extends SideBarItem {
  inventoryRoutesMatch: boolean
}

export const InventorySideBarItem = ({
  inventoryRoutesMatch,
  openAccordionKey,
  changeOpenAccordionKey,
  onCloseDrawer,
  sideBarIndexKey,
}: InventorySideBarItemProps) => {
  const { t } = useTranslation()
  const { currentHolder } = useSessionUser()

  const getSideNavLinkItems = () => {
    const sideNavItems: SideNavLinkItem[] = []
    if (currentHolder) {
      sideNavItems.push(
        {
          key: t('general.stockItems', 'Stock Items'),
          target: '/inventory/stock-items',
        },
        {
          key: t('general.stockLocations', 'Stock Locations'),
          target: '/inventory/stock-locations',
        },
        {
          key: t('general.transfers', 'Transfers'),
          target: '/inventory/transfers',
        },
        {
          key: t('general.stockTakes', 'Stocktakes'),
          target: '/inventory/stocktakes',
        }
      )
    } else {
      sideNavItems.push({
        key: t('general.stocktakes', 'Stocktakes'),
        target: '/stocktakes',
      })
    }
    return sideNavItems
  }

  return (
    <Accordion
      titlePrefixIcon={<InventoryIcon className="size-7" />}
      title={t('general.inventory', 'Inventory')}
      activeColor={inventoryRoutesMatch ? Themes.Primary : undefined}
      defaultOpen={openAccordionKey === sideBarIndexKey}
      handleTriggerClick={() => changeOpenAccordionKey(sideBarIndexKey)}
      buttonClassName="min-h-11 px-3"
      keepActiveColor
    >
      <SideNavLink
        onCloseDrawer={() => onCloseDrawer()}
        testId="inventory-navigation"
        sideNavLinkItems={getSideNavLinkItems()}
      />
    </Accordion>
  )
}
