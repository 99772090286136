import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PurchasingBaseErrorFragmentDoc } from './BaseError.generated'
import * as Types from './purchasing_graphql'

export const CreateInformalProductDocument = gql`
  mutation CreateInformalProduct($input: CreateInformalProductMutationInput!) {
    createInformalProduct(input: $input) {
      informalProduct {
        id
      }
      errors {
        ...PurchasingBaseError
      }
    }
  }
  ${PurchasingBaseErrorFragmentDoc}
` as unknown as DocumentNode<Types.CreateInformalProductMutation, Types.CreateInformalProductMutationVariables>
