import { useMutation } from '@apollo/client'

import { CreateCreditNoteLineDocument } from '@/graphql/purchase-plus/generated/createCreditNoteLine.generated'
import { PURCHASE_PLUS_GRAPHQL_API } from '@/modules/shared/constants'

export const useCreateCreditNoteLine = () => {
  const mutation = useMutation(CreateCreditNoteLineDocument, {
    context: {
      uri: PURCHASE_PLUS_GRAPHQL_API,
    },
  })

  return mutation
}
