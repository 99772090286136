import { useEffect, useState } from 'react'

import { UserViewPreferenceEnum } from '@/graphql/purchase-plus/generated/purchasePlus_graphql'
import i18nextConfig, { FELanguageEnum, i18nLocaleMapping, selectedLocale } from '@/locales/i18nextConfig'
import useSessionUser from '@/modules/access/hooks/useSessionUser'
import { useWindowSize } from '@/modules/shared/hooks/useWindowSize'
import { CSSVariables } from '@/modules/shared/types/CSSVariables'
import isPublicRoutes from '@/modules/shared/utils/isPublicRoutes'

type RGBValues = {
  red: number
  green: number
  blue: number
}

export function useSession() {
  const { user, loading, error } = useSessionUser({ skip: isPublicRoutes() })
  const [userSelectedLocale, setUserSelectedLocale] = useState<FELanguageEnum>(selectedLocale())
  const { currentOrganisation } = useSessionUser()
  const { sm } = useWindowSize()
  const ppxAccess = currentOrganisation?.ppxAccess
  const setColorProperty = (name: CSSVariables, value: RGBValues) => {
    const rgbString = `${value.red}, ${value.green}, ${value.blue}`
    document.documentElement.style.setProperty(name, rgbString)
  }

  // define theme color to global css variable
  useEffect(() => {
    if (ppxAccess) {
      setColorProperty(CSSVariables.PrimaryColor, { red: 238, green: 176, blue: 32 })
      setColorProperty(CSSVariables.PrimaryColorLight, { red: 248, green: 223, blue: 165 })
      setColorProperty(CSSVariables.PrimaryColorLighter, { red: 254, green: 247, blue: 232 })
    } else {
      setColorProperty(CSSVariables.PrimaryColor, { red: 24, green: 119, blue: 242 })
      setColorProperty(CSSVariables.PrimaryColorLight, { red: 185, green: 214, blue: 251 })
      setColorProperty(CSSVariables.PrimaryColorLighter, { red: 231, green: 241, blue: 254 })
    }
  }, [ppxAccess])

  // check view mode setting and apply change if in compact view
  useEffect(() => {
    // Get stored original font size or the actual font size from the browser settings
    const storedFontSize = sessionStorage.getItem('originalFontSize')
    const originalFontSize = storedFontSize
      ? parseFloat(storedFontSize)
      : parseFloat(window.getComputedStyle(document.documentElement).fontSize)

    // Set original font size in local storage to use later
    sessionStorage.setItem('originalFontSize', String(originalFontSize))

    // show compact view if the user settings are compact, or if we are on mobile
    if (user?.applicationPreferences?.viewPreference === UserViewPreferenceEnum.CompactView || !sm) {
      // Calculate the adjusted font size
      const adjustedFontSize = originalFontSize * 0.9

      // Set the font size for the HTML element
      document.documentElement.style.fontSize = `${adjustedFontSize}px`
    } else if (user?.applicationPreferences?.viewPreference === UserViewPreferenceEnum.RegularView) {
      // If view preference is Regular, reset to the original font size
      document.documentElement.style.fontSize = sessionStorage.getItem('originalFontSize') + 'px'
    }
  }, [user?.applicationPreferences?.viewPreference, sm])

  // Update the i18next language based on the user's preferred language
  useEffect(() => {
    // Check if the defaultLanguage exists and is a valid key in i18nLocaleMapping
    const defaultLanguage = user?.applicationPreferences?.defaultLanguage
    const isValidDefaultLanguage = defaultLanguage && i18nLocaleMapping[defaultLanguage]
    if (isValidDefaultLanguage) {
      const mappedLocale = i18nLocaleMapping[defaultLanguage]
      // Check if the mapped locale is different from the currently selected locale
      if (mappedLocale !== selectedLocale()) {
        i18nextConfig(defaultLanguage)
        setUserSelectedLocale(selectedLocale(defaultLanguage))
      }
    }
  }, [user?.applicationPreferences?.defaultLanguage])

  return {
    userSelectedLocale,
    loading,
    error,
    currentUser: user,
  }
}
