import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const PurchasingSupplierDetailByLineFragmentDoc = gql`
  fragment PurchasingSupplierDetailByLine on Supplier {
    id
    name
    billingAddressCity
    billingAddressCountry
    billingAddressLine1
    billingAddressLine2
    billingAddressPostalCode
    billingAddressStateProvince
    email
    telephone
    deliveryChargeConfiguration {
      minimumOrderValueExTax
      deliveryChargeExTax
      deliveryChargeIncTax
      deliveryChargeTaxPercentage
    }
  }
` as unknown as DocumentNode<Types.PurchasingSupplierDetailByLineFragment, unknown>
