import { ComboboxOption } from '@headlessui/react'

import { CheckAltIcon } from '@/modules/shared/icons/CheckAltIcon'
import { classNames } from '@/modules/shared/utils/classNames'

interface ComboBoxOptionItemProps<DataType> {
  item: DataType
  testId: string | undefined
  keyExtractor: (item: DataType) => string | number
  getSelectedItem: (item: DataType) => string
  isClient?: boolean
  selectedItem: DataType | null
}

export function ComboboxOptionItem<T>({
  item,
  testId,
  keyExtractor,
  getSelectedItem,
  isClient,
  selectedItem,
}: ComboBoxOptionItemProps<T>) {
  const isItemSelected = selectedItem ? keyExtractor(selectedItem) === keyExtractor(item) : false
  return (
    <ComboboxOption
      data-testid={`option${testId ? `-${testId}` : ''}-${keyExtractor(item)}`}
      value={item}
      className={({ active }) =>
        classNames('relative cursor-default select-none py-2 pl-3 pr-9', {
          'bg-primary text-white': active,
          'text-gray-900': !active,
        })
      }
    >
      {({ active, selected }) => (
        <>
          <span
            className={classNames('block truncate ', {
              'font-semibold ': isItemSelected || selected,
            })}
          >
            {getSelectedItem(item)}
          </span>

          {(isItemSelected || selected) && (
            <span
              className={classNames('absolute inset-y-0 right-0 flex items-center pr-4', {
                'text-white': active,
                'text-primary': !active,
              })}
            >
              {isClient ? <CheckAltIcon className="size-4" /> : <CheckAltIcon className="size-5" aria-hidden="true" />}
            </span>
          )}
        </>
      )}
    </ComboboxOption>
  )
}
