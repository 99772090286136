import { Controller, useFormContext } from 'react-hook-form'

import { ComboboxClient } from '@/modules/shared/components/combobox/client/ComboboxClient'
import { measureNameValues } from '@/modules/shared/components/measure-name-dropdown/MeasureNameValues'

interface MeasureNameDropdownProps {
  disabled?: boolean
  defaultValue?: string | null
  resetToDefault?: boolean
  onChange?: (value: string) => void
  floating?: boolean
  hasError?: boolean
  errorMessage?: string
}

export default function MeasureNameDropdown({
  disabled,
  defaultValue,
  resetToDefault,
  onChange,
  floating,
  hasError,
  errorMessage,
}: MeasureNameDropdownProps) {
  const formMethods = useFormContext()

  return (
    <Controller
      control={formMethods.control}
      name="itemMeasure"
      render={({ field }) => (
        <ComboboxClient
          items={measureNameValues}
          keyExtractor={(e) => e.value}
          defaultValue={measureNameValues.find(
            (measure) => measure.value === ((!resetToDefault && defaultValue) || 'each')
          )}
          keyFilter="value"
          onSelected={(e) => (onChange ? onChange(e.value) : field.onChange(e.value))}
          disabled={disabled}
          testId="measureName"
          floating={floating}
          hasError={hasError}
          errorMessage={errorMessage}
        ></ComboboxClient>
      )}
    />
  )
}
