import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const GetUserPagePreferenceDocument = gql`
  query GetUserPagePreference($key: String!, $userId: Int!) {
    user(id: $userId) {
      id
      pagePreference(key: $key) {
        key
        value
      }
    }
  }
` as unknown as DocumentNode<Types.GetUserPagePreferenceQuery, Types.GetUserPagePreferenceQueryVariables>
