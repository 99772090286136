import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PurchasePlusSupplierDetailByLineFragmentDoc } from './SupplierDetailByLine.generated'
import * as Types from './purchasePlus_graphql'

export const GetPricedCatalogueDocument = gql`
  query GetPricedCatalogue($pricedCatalogueId: Int!) {
    pricedCatalogue(id: $pricedCatalogueId) {
      id
      title
      updatedAt
      createdAt
      isContracted
      isSelfManaged
      externalId
      owner {
        id
        name
        isAPurchaser
        isASupplier
      }
      supplier {
        ...PurchasePlusSupplierDetailByLine
      }
    }
  }
  ${PurchasePlusSupplierDetailByLineFragmentDoc}
` as unknown as DocumentNode<Types.GetPricedCatalogueQuery, Types.GetPricedCatalogueQueryVariables>
