import { ComboboxButton } from '@headlessui/react'

import { Loading } from '@/modules/shared/icons-special/Loading'
import { ChevronDownIcon } from '@/modules/shared/icons/ChevronDownIcon'
import { classNames } from '@/modules/shared/utils/classNames'

interface ComboBoxOptionButtonProps {
  testId: string | undefined
  disabled?: boolean
  loading?: boolean
  isClient?: boolean
}

const ComboboxOptionButton = ({ testId, disabled, loading }: ComboBoxOptionButtonProps) => {
  return (
    <ComboboxButton
      data-testid={`combobox-button${testId ? `-${testId}` : ''}`}
      className={classNames('absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none', {
        'cursor-not-allowed': disabled,
      })}
    >
      {loading ? (
        <Loading className="size-5 fill-white text-gray-300" />
      ) : (
        <ChevronDownIcon className="h-7 w-6 text-gray-400" aria-hidden="true" />
      )}
    </ComboboxButton>
  )
}

export default ComboboxOptionButton
