import { ApolloError, useQuery } from '@apollo/client'

import { GetCurrentSessionProductBarcodesDocument } from '@/graphql/purchasing/generated/getCurrentSessionProductBarcodes.generated'
import { Barcode, RansackDirection } from '@/graphql/purchasing/generated/purchasing_graphql'
import useSessionUser from '@/modules/access/hooks/useSessionUser'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import useProductId from '@/modules/shared/hooks/params/useProductId'
import { checkNetworkStatus } from '@/modules/shared/utils/checkNetworkStatus'
import { extractEdges } from '@/modules/shared/utils/extractEdges'

interface UseGetCurrentSessionProductBarcodesProps {
  productIdProp?: string
  skip?: boolean
}

export default function useGetCurrentSessionProductBarcodes(args?: UseGetCurrentSessionProductBarcodesProps) {
  const { productIdProp, skip } = args || {}
  const productIdFromParams = useProductId()
  const { currentOrganisation } = useSessionUser()

  const productId = productIdProp || productIdFromParams
  const { data, networkStatus, error } = useQuery(GetCurrentSessionProductBarcodesDocument, {
    variables: {
      productId: Number(productId),
      nextGenQuotes: currentOrganisation?.featureFlags?.useNextGenQuotes,
      sort: [
        {
          property: 'createdAt',
          direction: RansackDirection.Desc,
        },
      ],
    },
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    skip: !productId || skip,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  })

  const productData = data?.currentLegalEntity?.availableProducts?.edges?.[0]?.node
  const loadingStates = checkNetworkStatus(networkStatus)

  return {
    error: (error || (!productData && !loadingStates.loading)) as ApolloError,
    barcodes: extractEdges<Barcode>(productData?.barcodes),
    loadingStates,
  }
}
