import { z } from 'zod'

import { ProductListType } from '@/modules/shared/types/ProductListType'

export const CreateProductFormSchemaWithType = (type: ProductListType) => {
  const catalog = type === ProductListType.AddCatalogProduct
  const receivingNote = type === ProductListType.AddReceivingNoteProduct
  const invoiceLine = type === ProductListType.AddInvoiceLine
  const requisitionLine = type === ProductListType.AddRequisitionProduct
  const creditNoteLine = type === ProductListType.AddCreditNoteLine

  return z.object({
    categoryId: z.number(),
    supplierId: !requisitionLine ? z.number().optional() : z.number(),
    brand: z.string().optional(),
    itemDescription: z.string().min(1),
    itemSize: z.number(),
    itemMeasure: z.string(),
    itemPackName: z.string().optional(),
    code: z.string().optional(),
    itemSellQuantity: z.number(),
    itemSellPackName: z.string(),
    unitPrice:
      catalog || receivingNote || invoiceLine || requisitionLine || creditNoteLine
        ? z.number().min(0.01)
        : z.number().optional(),
    taxPercentage: catalog || invoiceLine || requisitionLine || creditNoteLine ? z.number() : z.number().optional(),
    quantity:
      receivingNote || invoiceLine || requisitionLine || creditNoteLine ? z.number().min(0.001) : z.number().optional(),
    departmentId: invoiceLine ? z.number() : z.number().optional(),
    accountId: invoiceLine ? z.number() : z.number().optional(),
    externalId: z.string().optional(),
  })
}

export const CreateProductFormSchema = z.object({
  categoryId: z.number(),
  supplierId: z.number(),
  brand: z.string().optional(),
  itemDescription: z.string().min(1),
  itemSize: z.number(),
  itemMeasure: z.string(),
  itemPackName: z.string().nullable(),
  code: z.string().optional(),
  itemSellQuantity: z.number(),
  itemSellPackName: z.string(),
  unitPrice: z.number().min(0.01),
  taxPercentage: z.number(),
  quantity: z.number().min(0.001),
  departmentId: z.number(),
  accountId: z.number(),
  externalId: z.string().optional(),
})

export type CreateProductFormInputs = z.infer<typeof CreateProductFormSchema>
