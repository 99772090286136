import { useTranslation } from 'react-i18next'

import useSessionUser from '@/modules/access/hooks/useSessionUser'
import { Accordion } from '@/modules/shared/components/accordion/Accordion'
import CountBadge from '@/modules/shared/components/count-badge/CountBadge'
import { displayBadgeCount } from '@/modules/shared/components/count-badge/displayBadgeCount'
import { useGetDashboardStats } from '@/modules/shared/hooks/useGetDashboardStats'
import { BadgeIcon } from '@/modules/shared/icons-special/BadgeIcon'
import { InvoiceIcon } from '@/modules/shared/icons/InvoiceIcon'
import { PlusIcon } from '@/modules/shared/icons/PlusIcon'
import SideNavLink, { SideNavLinkItem } from '@/modules/shared/layouts/side-navigation/SideNavLink'
import { SideBarItem } from '@/modules/shared/layouts/side-navigation/purchaser-navigation/PurchaserNavigation'
import { Themes } from '@/modules/shared/types/Themes'

interface InvoicesSideBarItemProps extends SideBarItem {
  invoiceRoutesMatch: boolean
}

export const InvoicesSideBarItem = ({
  invoiceRoutesMatch,
  openAccordionKey,
  changeOpenAccordionKey,
  onCloseDrawer,
  sideBarIndexKey,
}: InvoicesSideBarItemProps) => {
  const { t } = useTranslation()
  const { dashboardStats } = useGetDashboardStats()

  const { userPermissions, currentOrganisation } = useSessionUser()

  const getSideNavLinkItems = () => {
    const sideNavItems: SideNavLinkItem[] = []
    if (userPermissions?.PURCHASER_CAN_MANAGE_INVOICES_AND_CREDIT_NOTES) {
      sideNavItems.push({
        key: t('general.newInvoice', 'New Invoice'),
        target: '/invoices/create',
        icon: <PlusIcon className="size-6 text-gray-500" />,
      })
    }
    sideNavItems.push(
      {
        key: t('general.viewAll', 'View All'),
        target: '/invoices',
      },
      {
        key: t('general.creditNotes', 'Credit Notes'),
        target: '/credit-notes',
      },
      {
        key: t('general.awaitingMyApproval', 'Awaiting My Approval'),
        target: '/invoices/awaiting-my-approval',
        icon: <CountBadge displayText={displayBadgeCount(dashboardStats?.invoicesAwaitingApprovalCount ?? 0, 25)} />,
      },
      {
        key: t('general.inbox', 'Inbox'),
        target: '/invoices/inbox',
      }
    )
    if (currentOrganisation?.featureFlags?.enableAiScanning) {
      sideNavItems.push({
        key: t('general.aiScanningInbox', 'AI Scanning Inbox'),
        target: '/invoices/ai-inbox',
      })
    }
    sideNavItems.push({ key: t('general.exportCenter', 'Export Center'), target: '/invoices/export-center' })
    return sideNavItems
  }

  return (
    <Accordion
      titlePrefixIcon={
        <BadgeIcon isBadged={!!dashboardStats?.invoicesAwaitingApprovalCount}>
          <InvoiceIcon className="size-7" />
        </BadgeIcon>
      }
      title={t('general.invoices', 'Invoices')}
      activeColor={invoiceRoutesMatch ? Themes.Primary : undefined}
      defaultOpen={openAccordionKey === sideBarIndexKey}
      handleTriggerClick={() => changeOpenAccordionKey(sideBarIndexKey)}
      buttonClassName="min-h-11 px-3"
      keepActiveColor
    >
      <SideNavLink
        onCloseDrawer={() => onCloseDrawer()}
        testId="invoice-navigation"
        sideNavLinkItems={getSideNavLinkItems()}
      />
    </Accordion>
  )
}
