import { useTranslation } from 'react-i18next'

import useSessionUser from '@/modules/access/hooks/useSessionUser'
import { Accordion } from '@/modules/shared/components/accordion/Accordion'
import { PlusIcon } from '@/modules/shared/icons/PlusIcon'
import { RecipeIcon } from '@/modules/shared/icons/RecipeIcon'
import SideNavLink, { SideNavLinkItem } from '@/modules/shared/layouts/side-navigation/SideNavLink'
import { SideBarItem } from '@/modules/shared/layouts/side-navigation/purchaser-navigation/PurchaserNavigation'
import { Themes } from '@/modules/shared/types/Themes'

interface RecipesSideBarItemProps extends SideBarItem {
  recipeRoutesMatch: boolean
}

export const RecipesSideBarItem = ({
  recipeRoutesMatch,
  openAccordionKey,
  changeOpenAccordionKey,
  onCloseDrawer,
  sideBarIndexKey,
}: RecipesSideBarItemProps) => {
  const { t } = useTranslation()
  const { userPermissions } = useSessionUser()

  const getSideNavLinkItems = () => {
    const sideNavItems: SideNavLinkItem[] = []
    if (userPermissions?.PURCHASER_CAN_MANAGE_RECIPES) {
      sideNavItems.push({
        key: t('general.newRecipe', 'New Recipe'),
        target: '/recipes/create',
        icon: <PlusIcon className="size-6 text-gray-500" />,
      })
    }
    sideNavItems.push(
      {
        key: t('general.viewAll', 'View All'),
        target: '/recipes',
      },
      {
        key: t('general.menus', 'Menus'),
        target: '/menus',
      },
      {
        key: t('general.ingredients', 'Ingredients'),
        target: '/ingredients',
      }
    )
    return sideNavItems
  }

  return (
    <Accordion
      titlePrefixIcon={<RecipeIcon className="size-7" />}
      title={t('general.recipes', 'Recipes')}
      activeColor={recipeRoutesMatch ? Themes.Primary : undefined}
      defaultOpen={openAccordionKey === sideBarIndexKey}
      handleTriggerClick={() => changeOpenAccordionKey(sideBarIndexKey)}
      buttonClassName="min-h-11 px-3"
      keepActiveColor
    >
      <SideNavLink
        onCloseDrawer={() => onCloseDrawer()}
        testId="recipes-navigation"
        sideNavLinkItems={getSideNavLinkItems()}
      />
    </Accordion>
  )
}
