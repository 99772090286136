import React from 'react'
import { NavLink } from 'react-router-dom'

import { Button } from '@/modules/shared/components/button/Button'
import { classNames } from '@/modules/shared/utils/classNames'

export interface SideNavLinkItem {
  key: string
  target?: string
  onClick?: () => void
  icon?: React.ReactElement
  newWindow?: boolean
}

interface SideNavLinkProps {
  onCloseDrawer: () => void
  sideNavLinkItems: SideNavLinkItem[]
  testId: string
}

const SideNavLink = ({ onCloseDrawer, sideNavLinkItems, testId }: SideNavLinkProps) => {
  return (
    <div className="ml-3 mt-2 space-y-1.5" data-testid={testId}>
      {sideNavLinkItems.map((e) => (
        <div key={e.key}>
          {e.onClick && !e.target && (
            <Button
              className="flex min-h-[2.375rem] w-full items-center justify-between gap-x-2 rounded-md px-4 py-2 transition duration-200 hover:bg-gray-100"
              onClick={e.onClick}
              data-testid={`${testId}-${e.key.toLowerCase().split(' ').join('-')}-btn`}
            >
              {e.key}
              {e.icon}
            </Button>
          )}
          {e.target && !e.onClick && (
            <NavLink
              data-testid={`${testId}-${e.key.toLowerCase().split(' ').join('-')}-link`}
              to={e.target}
              target={e.newWindow ? '_blank' : '_self'}
              end
              onClick={() => onCloseDrawer()}
              className={({ isActive }) => {
                return classNames(
                  'flex min-h-[2.375rem] items-center justify-between gap-x-2 rounded-md px-4 py-2 transition duration-200',
                  {
                    'bg-primary/10 text-primary hover:bg-primary/20': isActive,
                    'bg-gray-100 hover:bg-gray-200': !isActive,
                  }
                )
              }}
            >
              {e.key}
              {e.icon}
            </NavLink>
          )}
        </div>
      ))}
    </div>
  )
}

export default SideNavLink
