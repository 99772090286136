import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const ChangeOrganisationDocument = gql`
  mutation ChangeOrganisation($input: ChangeOrganisationInput!) {
    changeOrganisation(input: $input) {
      currentOrganisation {
        id
        name
      }
    }
  }
` as unknown as DocumentNode<Types.ChangeOrganisationMutation, Types.ChangeOrganisationMutationVariables>
