import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const PurchasePlusSupplierDetailByLineFragmentDoc = gql`
  fragment PurchasePlusSupplierDetailByLine on Supplier {
    id
    name
    billingAddressCity
    billingAddressCountry
    billingAddressLine1
    billingAddressLine2
    billingAddressPostalCode
    billingAddressStateProvince
    email
    telephone
  }
` as unknown as DocumentNode<Types.PurchasePlusSupplierDetailByLineFragment, unknown>
