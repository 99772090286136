import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const SignOutDocument = gql`
  mutation SignOut($input: SignOutInput!) {
    signOut(input: $input) {
      user {
        fullName
        id
        email
      }
    }
  }
` as unknown as DocumentNode<Types.SignOutMutation, Types.SignOutMutationVariables>
