import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PurchasingBarcodeFragmentDoc } from './Barcode.generated'
import * as Types from './purchasing_graphql'

export const GetCurrentSessionProductBarcodesDocument = gql`
  query GetCurrentSessionProductBarcodes($productId: Int, $sort: [RansackSortType!], $nextGenQuotes: Boolean) {
    currentLegalEntity {
      id
      ... on Supplier {
        availableProducts(productId: $productId) {
          edges {
            node {
              id
              barcodes(sort: $sort) {
                edges {
                  node {
                    ...PurchasingBarcode
                  }
                }
              }
            }
          }
        }
      }
      ... on Purchaser {
        accountsPayableSystemId
        availableProducts(productId: $productId, nextGenQuotes: $nextGenQuotes) {
          edges {
            node {
              id
              barcodes(sort: $sort) {
                edges {
                  node {
                    ...PurchasingBarcode
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${PurchasingBarcodeFragmentDoc}
` as unknown as DocumentNode<
  Types.GetCurrentSessionProductBarcodesQuery,
  Types.GetCurrentSessionProductBarcodesQueryVariables
>
