import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import useSessionUser from '@/modules/access/hooks/useSessionUser'
import { Accordion } from '@/modules/shared/components/accordion/Accordion'
import CreateProductModal from '@/modules/shared/components/create-product-modal/CreateProductModal'
import { ControlBarIcon } from '@/modules/shared/icons/ControlBarIcon'
import { PlusIcon } from '@/modules/shared/icons/PlusIcon'
import SideNavLink, { SideNavLinkItem } from '@/modules/shared/layouts/side-navigation/SideNavLink'
import { SideBarItem } from '@/modules/shared/layouts/side-navigation/purchaser-navigation/PurchaserNavigation'
import { ProductListType } from '@/modules/shared/types/ProductListType'
import { Themes } from '@/modules/shared/types/Themes'

interface ProductSideBarItemProps extends SideBarItem {
  myProductRoutesMatch: boolean
  isSupplier?: boolean
}

export const ProductSideBarItem = ({
  myProductRoutesMatch,
  openAccordionKey,
  changeOpenAccordionKey,
  onCloseDrawer,
  isSupplier,
  sideBarIndexKey,
}: ProductSideBarItemProps) => {
  const { t } = useTranslation()
  const [showCreateProductModal, setShowCreateProductModal] = useState(false)
  const { userPermissions, user } = useSessionUser()

  const getSideNavLinkItems = () => {
    const sideNavLinkItems: SideNavLinkItem[] = []
    if (
      (userPermissions?.PURCHASER_CAN_MANAGE_PRODUCTS && !isSupplier) ||
      (userPermissions?.SUPPLIER_CAN_MANAGE_PRODUCTS && isSupplier)
    ) {
      sideNavLinkItems.push({
        key: t('general.newProduct', 'New Product'),
        icon: <PlusIcon className="size-6 text-gray-500" />,
        onClick: () => setShowCreateProductModal(true),
      })
    }
    sideNavLinkItems.push({
      key: t('general.viewAll', 'View All'),
      target: '/products',
    })

    if (isSupplier && user?.isAdmin) {
      sideNavLinkItems.push({
        key: t('general.legacyProducts', 'Legacy Products'),
        target: '/products/legacy',
      })
    }

    sideNavLinkItems.push({
      key: t('general.barcodes', 'Barcodes'),
      target: '/barcodes',
    })

    return sideNavLinkItems
  }

  return (
    <Accordion
      titlePrefixIcon={<ControlBarIcon className="size-7" />}
      title={t('general.products', 'Products')}
      buttonClassName="min-h-11 px-3"
      activeColor={myProductRoutesMatch ? Themes.Primary : undefined}
      defaultOpen={openAccordionKey === sideBarIndexKey}
      handleTriggerClick={() => changeOpenAccordionKey(sideBarIndexKey)}
      keepActiveColor
    >
      <SideNavLink
        onCloseDrawer={() => onCloseDrawer()}
        testId="my-products-navigation"
        sideNavLinkItems={getSideNavLinkItems()}
      />
      <CreateProductModal
        type={ProductListType.AllProducts}
        showModal={showCreateProductModal}
        setShowModal={setShowCreateProductModal}
        title={t('general.createNewProduct', 'Create New Product')}
      />
    </Accordion>
  )
}
