import { useMutation } from '@apollo/client'

import { UpdatePreferenceDocument } from '@/graphql/purchase-plus/generated/updatePreference.generated'
import { PURCHASE_PLUS_GRAPHQL_API } from '@/modules/shared/constants'

export default function useUpdatePreference() {
  const mutation = useMutation(UpdatePreferenceDocument, {
    context: { uri: PURCHASE_PLUS_GRAPHQL_API },
  })

  return mutation
}
