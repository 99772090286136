import { ApolloError, useQuery } from '@apollo/client'

import { GetProductSubstituteProductsDocument } from '@/graphql/purchase-plus/generated/getProductSubstituteProducts.generated'
import { SubstituteProduct } from '@/graphql/purchase-plus/generated/purchasePlus_graphql'
import { PURCHASE_PLUS_GRAPHQL_API } from '@/modules/shared/constants'
import useProductId from '@/modules/shared/hooks/params/useProductId'
import { useWatchQueryParams } from '@/modules/shared/hooks/useWatchQueryParams'
import { checkNetworkStatus } from '@/modules/shared/utils/checkNetworkStatus'
import { extractEdges } from '@/modules/shared/utils/extractEdges'
import { onFetchMore, onNextPage, onPreviousPage } from '@/modules/shared/utils/paginationUtils'

interface UseGetProductSubstituteProductsProps {
  productId: number
}

export default function useGetProductSubstituteProducts(args?: UseGetProductSubstituteProductsProps) {
  const { productId: productIdProp } = args || {}
  const productId = useProductId()
  const { searchValue } = useWatchQueryParams()

  const paginationResultsPerPage = 20
  const { data, networkStatus, error, refetch, fetchMore } = useQuery(GetProductSubstituteProductsDocument, {
    variables: {
      productId: Number(productIdProp || productId),
      first: paginationResultsPerPage,
      after: null,
      filters: {
        searchText: searchValue,
      },
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    context: { uri: PURCHASE_PLUS_GRAPHQL_API },
  })

  const productData = data?.product
  const pageInfo = productData?.substituteProducts?.pageInfo
  const loadingStates = checkNetworkStatus(networkStatus)

  return {
    substituteProducts: extractEdges<SubstituteProduct>(productData?.substituteProducts),
    loadingStates,
    error: (error || (!productData && !loadingStates.loading)) as ApolloError,
    refetch,
    paginationResultsPerPage,
    pageInfo,
    onNextPage: () =>
      onNextPage({
        endCursor: pageInfo?.endCursor || null,
        refetch,
        paginationResultsPerPage,
      }),
    onPreviousPage: () =>
      onPreviousPage({
        startCursor: pageInfo?.startCursor || null,
        refetch,
        paginationResultsPerPage,
      }),
    onFetchMore: () =>
      onFetchMore({
        endCursor: pageInfo?.endCursor || null,
        fetchMore,
        paginationResultsPerPage,
      }),
  }
}
