import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const PurchasePlusPageInfoFragmentDoc = gql`
  fragment PurchasePlusPageInfo on PageInfo {
    startCursor
    endCursor
    hasPreviousPage
    hasNextPage
  }
` as unknown as DocumentNode<Types.PurchasePlusPageInfoFragment, unknown>
