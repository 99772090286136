import { gql } from '@apollo/client'

import { BARCODE } from '@/graphql/purchasing/operations/fragments/Barcode'
import { PAGE_INFO } from '@/graphql/purchasing/operations/fragments/PageInfo'
import { PRODUCT } from '@/graphql/purchasing/operations/fragments/Product'

export const GET_AVAILABLE_PRODUCTS = gql`
  ${BARCODE}
  ${PRODUCT}
  ${PAGE_INFO}
  query GetAvailableProducts(
    $searchText: String
    $after: String
    $categoryIds: [Int!]
    $supplierIds: [Int!]
    $hasQuotes: Boolean
    $supplierProductCode: String
    $purchaserProductCode: String
    $productId: Int
    $master: Boolean
    $invoiceId: Int!
    $catalogueId: Int!
    $requisitionId: Int!
    $first: Int
    $excludeCatalogueId: Int
    $sort: [RansackSortType!]
    $barcode: String
    $sellQuantity: Float
    $ownerId: Int
    $single: Boolean
    $nextGenQuotes: Boolean
  ) {
    currentPurchaser {
      id
      availableProducts(
        searchText: $searchText
        after: $after
        categoryIds: $categoryIds
        supplierIds: $supplierIds
        hasQuotes: $hasQuotes
        supplierProductCode: $supplierProductCode
        purchaserProductCode: $purchaserProductCode
        productId: $productId
        master: $master
        first: $first
        excludeCatalogueId: $excludeCatalogueId
        sort: $sort
        barcode: $barcode
        sellQuantity: $sellQuantity
        ownerId: $ownerId
        single: $single
        nextGenQuotes: $nextGenQuotes
      ) {
        edges {
          node {
            ...Product
            inBuyListCount
            productCode
            invoiced(invoiceId: $invoiceId)
            catalogued(catalogueId: $catalogueId)
            requisitioned(requisitionId: $requisitionId)
            stockItem
            barcodes {
              edges {
                node {
                  ...PurchasingBarcode
                }
              }
            }
            availableQuotes(nextGenQuotes: $nextGenQuotes) {
              supplier {
                id
                name
              }
              catalogue {
                id
              }
              unitPrice
              taxPercentage
              contracted
            }
            catalogues {
              edges {
                node {
                  id
                  title
                }
              }
            }
          }
        }
        pageInfo {
          ...PurchasingPageInfo
        }
      }
    }
  }
`
