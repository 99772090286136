import { useMutation } from '@apollo/client'

import { UpdateUserDocument } from '@/graphql/purchase-plus/generated/updateUser.generated'
import { PURCHASE_PLUS_GRAPHQL_API } from '@/modules/shared/constants'

export default function useUpdateUser() {
  const mutation = useMutation(UpdateUserDocument, {
    context: {
      uri: PURCHASE_PLUS_GRAPHQL_API,
    },
  })
  return mutation
}
