import { gql } from '@apollo/client'

export const BARCODE = gql`
  fragment PurchasingBarcode on Barcode {
    id
    body
    isDefault
    organisation {
      id
      name
    }
  }
`
