import { FetchPolicy, useLazyQuery } from '@apollo/client'
import { useEffect } from 'react'

import { GetAccountsPayableSystemSuppliersDocument } from '@/graphql/purchase-plus/generated/getAccountsPayableSystemSuppliers.generated'
import {
  SortDirectionEnum,
  Supplier,
  SuppliersQuerySortsPropertyEnum,
  TradeRelationshipStatusEnum,
} from '@/graphql/purchase-plus/generated/purchasePlus_graphql'
import { PURCHASE_PLUS_GRAPHQL_API } from '@/modules/shared/constants'
import { useWatchQueryParams } from '@/modules/shared/hooks/useWatchQueryParams'
import { checkNetworkStatus } from '@/modules/shared/utils/checkNetworkStatus'
import { extractEdges } from '@/modules/shared/utils/extractEdges'
import { onFetchMore, onNextPage, onPreviousPage } from '@/modules/shared/utils/paginationUtils'

interface UseGetAccountsPayableSystemSuppliersProps {
  lazyQuery?: boolean
  fetchPolicy?: FetchPolicy
  status?: TradeRelationshipStatusEnum[]
  isVerified?: boolean | null
  preferredSupplier?: boolean | null
  purchaserId?: number
  accountsPayableSystemId?: number
}

export const useGetAccountsPayableSystemSuppliers = (args?: UseGetAccountsPayableSystemSuppliersProps) => {
  const { lazyQuery, fetchPolicy, status, purchaserId, accountsPayableSystemId, preferredSupplier, isVerified } =
    args || {}
  const paginationResultsPerPage = 20

  const { searchValue } = useWatchQueryParams()

  const [fetchAccountsPayableSystemSuppliers, { data, error, fetchMore, networkStatus, refetch }] = useLazyQuery(
    GetAccountsPayableSystemSuppliersDocument,
    {
      variables: {
        first: paginationResultsPerPage,
        after: null,
        filters: {
          searchText: searchValue,
          preferredSupplier,
          isVerified,
          purchaserId,
          status,
        },
        accountsPayableSystemId,
        sorts: [{ property: SuppliersQuerySortsPropertyEnum.Name, direction: SortDirectionEnum.Asc }],
      },
      context: {
        uri: PURCHASE_PLUS_GRAPHQL_API,
      },
      fetchPolicy: fetchPolicy || 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    }
  )

  useEffect(() => {
    if (!lazyQuery) {
      fetchAccountsPayableSystemSuppliers()
    }
  }, [])

  const pageInfo = data?.accountsPayableSystem?.suppliers?.pageInfo

  return {
    fetchAccountsPayableSystemSuppliers,
    suppliers: extractEdges<Supplier>(data?.accountsPayableSystem?.suppliers),
    refetch,
    loadingStates: checkNetworkStatus(networkStatus),
    error,
    paginationResultsPerPage,
    pageInfo,
    networkStatus,
    onNextPage: () =>
      onNextPage({
        endCursor: pageInfo?.endCursor || null,
        refetch,
        paginationResultsPerPage,
      }),
    onPreviousPage: () =>
      onPreviousPage({
        startCursor: pageInfo?.startCursor || null,
        refetch,
        paginationResultsPerPage,
      }),
    onFetchMore: () =>
      onFetchMore({
        endCursor: pageInfo?.endCursor || null,
        fetchMore,
        paginationResultsPerPage,
      }),
  }
}
