import { useMutation } from '@apollo/client'

import { ChangeOrganisationDocument } from '@/graphql/purchase-plus/generated/changeOrganisation.generated'
import { PURCHASE_PLUS_GRAPHQL_API } from '@/modules/shared/constants'

export default function useChangeOrganisation() {
  const mutation = useMutation(ChangeOrganisationDocument, {
    context: { uri: PURCHASE_PLUS_GRAPHQL_API },
  })

  return mutation
}
