import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PurchasePlusBaseErrorFragmentDoc } from './BaseError.generated'
import * as Types from './purchasePlus_graphql'

export const CreateCreditNoteLineDocument = gql`
  mutation CreateCreditNoteLine($input: CreateCreditNoteLineInput!) {
    createCreditNoteLine(input: $input) {
      creditNoteLine {
        id
      }
      errors {
        ...PurchasePlusBaseError
      }
    }
  }
  ${PurchasePlusBaseErrorFragmentDoc}
` as unknown as DocumentNode<Types.CreateCreditNoteLineMutation, Types.CreateCreditNoteLineMutationVariables>
