import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Supplier, TradeRelationshipStatusEnum } from '@/graphql/purchase-plus/generated/purchasePlus_graphql'
import { useCurrentPurchaser } from '@/modules/purchasing/hooks/useCurrentPurchaser'
import ComboboxServer from '@/modules/shared/components/combobox/server/ComboboxServer'
import { useGetAccountsPayableSystemSuppliers } from '@/modules/suppliers/hooks/useGetAccountsPayableSystemSuppliers'

export interface PureSupplierRelationshipsSelectionProps {
  onSelected: (e: Supplier | null) => void
  defaultValue?: Supplier
  onChipRemove?: () => void
  placeholder?: string
  hasError?: boolean
  errorMessage?: string
  floating?: boolean
  loading?: boolean
  showChips?: boolean
  disabled?: boolean
  apSystemId?: number
  purchaserId?: number
  onDisplay?: (e: Supplier) => string
}

export function PureSupplierRelationshipsSelection({
  onSelected,
  placeholder,
  hasError,
  errorMessage,
  floating,
  onChipRemove,
  defaultValue,
  loading,
  showChips = true,
  disabled,
  apSystemId,
  onDisplay,
  purchaserId,
}: PureSupplierRelationshipsSelectionProps) {
  const { t } = useTranslation()
  const [searchText, setSearchText] = useState('')
  const { currentPurchaser } = useCurrentPurchaser()
  const { suppliers, networkStatus, onFetchMore, pageInfo, refetch } = useGetAccountsPayableSystemSuppliers({
    status: [TradeRelationshipStatusEnum.Mutual],
    purchaserId: purchaserId || currentPurchaser?.id,
    accountsPayableSystemId: apSystemId,
  })

  useEffect(() => {
    refetch({
      filters: { searchText, enabled: true, purchaserId: purchaserId || currentPurchaser?.id },
      accountsPayableSystemId: apSystemId,
    })
  }, [searchText])

  return (
    <ComboboxServer
      testId="pure-supplier-relationships-selection"
      networkStatus={networkStatus}
      placeholder={placeholder || t('general.selectSupplierPlaceholder', 'Select a Supplier...')}
      keyExtractor={(e) => String(e?.id)}
      onDisplay={(e) => (onDisplay ? onDisplay(e) : String(e.name))}
      items={suppliers}
      onInputChange={(e) => setSearchText(e)}
      onChipRemove={showChips ? onChipRemove : undefined}
      onSelected={onSelected}
      hasError={hasError}
      loading={loading}
      errorMessage={errorMessage}
      defaultValue={defaultValue}
      floating={floating}
      disabled={disabled}
      onFetchMore={onFetchMore}
      hasMore={!!pageInfo?.hasNextPage}
    />
  )
}
