import { WatchQueryFetchPolicy, useLazyQuery } from '@apollo/client'
import { useEffect } from 'react'

import { GetAllOrganisationsDocument } from '@/graphql/purchase-plus/generated/getAllOrganisations.generated'
import {
  OrganisationInterface,
  RansackDirection,
  RansackMatcher,
} from '@/graphql/purchase-plus/generated/purchasePlus_graphql'
import { PURCHASE_PLUS_GRAPHQL_API } from '@/modules/shared/constants'
import { useWatchQueryParams } from '@/modules/shared/hooks/useWatchQueryParams'
import { checkNetworkStatus } from '@/modules/shared/utils/checkNetworkStatus'
import { extractEdges } from '@/modules/shared/utils/extractEdges'
import { onFetchMore, onNextPage, onPreviousPage } from '@/modules/shared/utils/paginationUtils'

interface UseGetAllOrganisationsProps {
  isPurchasers?: boolean
  onlyParents?: boolean
  lazyQuery?: boolean
  fetchPolicy?: WatchQueryFetchPolicy
  hideUnverifiedSuppliers?: boolean
}

export default function useGetAllOrganisations(args?: UseGetAllOrganisationsProps) {
  const { isPurchasers, onlyParents, lazyQuery, fetchPolicy, hideUnverifiedSuppliers } = args || {}
  const paginationResultsPerPage = 20
  const { searchValue, id, verified, legalEntity } = useWatchQueryParams()

  const generateFilters = (): RansackMatcher[] => {
    const filter: RansackMatcher[] = []

    if (isPurchasers) {
      filter.push({
        property: 'isAPurchaser_eq',
        value: true,
      })
    } else if (isPurchasers === false) {
      filter.push({
        property: 'isASupplier_eq',
        value: true,
      })
    }
    if (searchValue) {
      filter.push({
        property: 'searchText_fuzzy',
        value: searchValue,
      })
    }

    if (id) {
      filter.push({
        property: 'id_eq',
        value: id,
      })
    }

    if (hideUnverifiedSuppliers) {
      filter.push({
        property: 'isVerified_or_isAPurchaser_eq',
        value: true,
      })
    }

    if (verified != null) {
      filter.push({
        property: 'isVerified_eq',
        value: verified,
      })
    }

    if (legalEntity != null) {
      filter.push({
        property: 'legalEntity_eq',
        value: legalEntity,
      })
    }

    if (onlyParents) {
      filter.push({
        property: 'parentId_null',
        value: 1,
      })
    }

    return filter
  }

  const [fetchAllOrganisations, { data, error, networkStatus, fetchMore, refetch }] = useLazyQuery(
    GetAllOrganisationsDocument,
    {
      variables: {
        first: paginationResultsPerPage,
        filter: {
          q: generateFilters(),
        },
        sort: [
          {
            direction: RansackDirection.Desc,
            property: 'created_at',
          },
        ],
      },
      context: { uri: PURCHASE_PLUS_GRAPHQL_API },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: fetchPolicy || 'cache-and-network',
    }
  )

  const pageInfo = data?.organisations?.pageInfo

  useEffect(() => {
    if (!lazyQuery) fetchAllOrganisations()
  }, [])

  return {
    organisations: extractEdges<OrganisationInterface>(data?.organisations),
    error,
    paginationResultsPerPage,
    loadingStates: checkNetworkStatus(networkStatus),
    onFetchMoreOrganisations: () =>
      onFetchMore({
        endCursor: pageInfo?.endCursor || null,
        fetchMore,
        paginationResultsPerPage,
      }),
    onNextPage: () =>
      onNextPage({
        endCursor: pageInfo?.endCursor || null,
        refetch,
        paginationResultsPerPage,
      }),
    onPreviousPage: () =>
      onPreviousPage({
        startCursor: pageInfo?.startCursor || null,
        refetch,
        paginationResultsPerPage,
      }),
    pageInfo,
    networkStatus,
    refetch,
    fetchAllOrganisations,
  }
}
