import { t } from 'i18next'
import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import { Category, Maybe, Product } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Input as FormatProductInput } from '@/modules/requisitions/utils/formatProductItem'
import { CreateProductFormInputs } from '@/modules/shared/components/create-product-modal/CreateProductFormSchema'
import { ProductImage } from '@/modules/shared/components/table/table-column-items/shared/ProductImage'
import { ProductItem } from '@/modules/shared/components/table/table-column-items/shared/ProductItem'

interface CreateProductPreviewProps {
  selectedCategory: Category | null
  formatProduct?: (input: FormatProductInput) => string
  image?: Maybe<string>
  currentProduct?: Product
}

export default function CreateProductPreview({
  selectedCategory,
  image,
  formatProduct,
  currentProduct,
}: CreateProductPreviewProps) {
  const formMethods = useFormContext<CreateProductFormInputs>()

  const product = useMemo(() => {
    const baseProduct = { ...currentProduct } as Product

    if (formMethods) {
      const formValues = formMethods.getValues() || {}
      const formProduct = {
        categoryId: formValues.categoryId,
        brand: String(formValues.brand),
        itemDescription: formValues.itemDescription,
        itemSize: formValues.itemSize,
        itemMeasure: formValues.itemMeasure,
        itemPackName: formValues.itemPackName,
        itemSellQuantity: formValues.itemSellQuantity,
        itemSellPackName: formValues.itemSellPackName,
      }

      Object.assign(baseProduct, formProduct)
    }

    return baseProduct
  }, [currentProduct, formMethods])

  formMethods?.watch()

  product.category = selectedCategory
  product.concatenatedSellUnit =
    product.itemSellPackName && product.itemSellQuantity
      ? `${product.itemSellPackName} of ${product.itemSellQuantity}`
      : null

  if (product.itemPackName === 'null') {
    product.itemPackName = null
  }

  return (
    <div>
      <p className="font-bold">{t('general.productPreview', 'Product Preview')}</p>
      <p className="mb-3 text-gray-500">
        {t(
          'createProduct.productPreview.description',
          'As you complete the fields above, a preview of the product will appear here.'
        )}
      </p>
      <div className="flex gap-x-2 rounded-md border bg-white p-4">
        <ProductImage image={image || product?.image || ''} />
        <div className="flex flex-col justify-center">
          <ProductItem product={{ ...product, image: image || product?.image || '' }} formatProduct={formatProduct} />
          <p className="text-xs">
            {selectedCategory &&
              `${selectedCategory?.parent?.name ? `${selectedCategory?.parent?.name} > ` : ''}${selectedCategory?.name}`}
          </p>
        </div>
      </div>
    </div>
  )
}
