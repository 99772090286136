import { useTranslation } from 'react-i18next'

import { Accordion } from '@/modules/shared/components/accordion/Accordion'
import { ChartIcon } from '@/modules/shared/icons/ChartIcon'
import SideNavLink from '@/modules/shared/layouts/side-navigation/SideNavLink'
import { SideBarItem } from '@/modules/shared/layouts/side-navigation/purchaser-navigation/PurchaserNavigation'
import { Themes } from '@/modules/shared/types/Themes'

interface ReportsSideBarItemProps extends SideBarItem {
  reportsRoutesMatch: boolean
}

export const ReportsSideBarItem = ({
  reportsRoutesMatch,
  openAccordionKey,
  changeOpenAccordionKey,
  onCloseDrawer,
  sideBarIndexKey,
}: ReportsSideBarItemProps) => {
  const { t } = useTranslation()
  return (
    <Accordion
      titlePrefixIcon={<ChartIcon className="size-7" />}
      title={t('general.reports', 'Reports')}
      activeColor={reportsRoutesMatch ? Themes.Primary : undefined}
      defaultOpen={openAccordionKey === sideBarIndexKey}
      handleTriggerClick={() => changeOpenAccordionKey(sideBarIndexKey)}
      buttonClassName="min-h-11 px-3"
      keepActiveColor
    >
      <SideNavLink
        onCloseDrawer={() => onCloseDrawer()}
        testId="reports-navigation"
        sideNavLinkItems={[
          {
            key: t('general.viewAll', 'View All'),
            target: '/reports',
          },
        ]}
      />
      {/* TODO: Uncomment with all templates view */}
      {/* <SideNavLink
        onCloseDrawer={() => onCloseDrawer()}
        testId="reports-templates-navigation"
        sideNavLinkItems={[
          {
            key: t('general.savedTemplates', 'Saved Templates'),
            target: '/reports/templates',
          },
        ]}
      /> */}
    </Accordion>
  )
}
