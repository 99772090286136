import { autoUpdate, size, useFloating } from '@floating-ui/react-dom'
import { Combobox, ComboboxInput, ComboboxOptions, Portal } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import ComboboxOptionButton from '@/modules/shared/components/combobox/shared/ComboboxOptionButton'
import { ComboboxOptionItem } from '@/modules/shared/components/combobox/shared/ComboboxOptionItem'
import { ComboBoxBaseProps } from '@/modules/shared/components/combobox/types'
import { classNames } from '@/modules/shared/utils/classNames'

interface ComboboxClientProps<DataType> extends ComboBoxBaseProps<DataType> {
  keyFilter: keyof DataType
  resetSelectItem?: number
  allowClear?: boolean
}

export function ComboboxClient<T>(props: ComboboxClientProps<T>) {
  const {
    placeholder,
    keyFilter,
    keyExtractor,
    loading,
    disabled,
    className,
    onSelected,
    defaultValue,
    hasError,
    errorMessage,
    items = [],
    testId,
    resetSelectItem,
    allowClear,
    floating,
  } = props
  const { t } = useTranslation()

  const [query, setQuery] = useState('')
  const [selectedItem, setSelectedItem] = useState<T | null>(null)
  const {
    x,
    y,
    strategy,
    refs: { setReference, setFloating },
  } = useFloating({
    placement: 'bottom',
    whileElementsMounted: autoUpdate,
    middleware: [
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
          })
        },
      }),
    ],
  })

  const filteredItems = () => {
    if (!query) return items

    return items.filter((item) => {
      const val = String(item[keyFilter])
      return val.toLowerCase().includes(query.toLowerCase())
    })
  }

  const getSelectedItem = (item: T) => {
    return item && String(item[keyFilter])
  }

  useEffect(() => {
    if (selectedItem) {
      onSelected(selectedItem)
    }
  }, [selectedItem])

  useEffect(() => {
    if (resetSelectItem) {
      setSelectedItem(null)
    }
  }, [resetSelectItem])

  useEffect(() => {
    if (defaultValue) {
      setSelectedItem(defaultValue)
    }
  }, [])

  // Headless UI combobox isn't rendered in a portal so the combobox dropdown will be cut off by the table component (or any other components)
  // So we have to render the dropdown in a portal and use useFloating hook to position it correctly.
  // Note: Turn on `floating` if you want to render the combobox dropdown in a portal.
  // Reference: https://github.com/tailwindlabs/headlessui/discussions/1925
  const FloatingPortal = floating ? Portal : Fragment
  const floatingStyles = floating
    ? {
        position: strategy,
        top: y ?? 0,
        left: x ?? 0,
      }
    : undefined

  return (
    <Combobox
      as="div"
      className={classNames('w-full', className)}
      disabled={disabled || loading}
      value={selectedItem}
      onChange={setSelectedItem}
      immediate
    >
      <div className={classNames('relative', { 'pb-1': hasError })}>
        <ComboboxInput
          ref={floating ? setReference : null}
          aria-invalid={hasError ? 'true' : 'false'}
          autoComplete="off"
          data-testid={`combobox-input${testId ? `-${testId}` : ''}`}
          className={classNames(
            'w-full rounded-md border border-gray-300 py-3 pl-3 pr-10 text-sm shadow-sm transition focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm',
            {
              'bg-gray-200/50': loading,
              'text-gray-300': loading,
              'cursor-not-allowed': disabled,
              'border-error': hasError,
              'bg-gray-200': disabled,
            }
          )}
          onChange={(event) => setQuery(event.target.value)}
          placeholder={placeholder}
          displayValue={(item: T) => getSelectedItem(item)}
        />

        <ComboboxOptionButton testId={testId} disabled={disabled} loading={loading} />

        {filteredItems().length > 0 && (
          <FloatingPortal>
            <ComboboxOptions
              ref={floating ? setFloating : null}
              style={floatingStyles}
              data-testid={`options-wrapper${testId ? `-${testId}` : ''}`}
              className={classNames(
                'absolute z-10 mt-1 max-h-72 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-primary/5 focus:outline-none sm:text-sm',
                { 'w-full': !floating }
              )}
            >
              {allowClear && (
                <ComboboxOptionItem
                  item={{ [keyFilter]: t('general.none', 'None') } as unknown as T}
                  testId={testId}
                  keyExtractor={keyExtractor}
                  getSelectedItem={getSelectedItem}
                  selectedItem={selectedItem}
                  isClient={true}
                />
              )}
              {filteredItems().map((item, index) => (
                <ComboboxOptionItem
                  key={`combobox-option-item-${index}`}
                  item={item}
                  testId={testId}
                  keyExtractor={keyExtractor}
                  getSelectedItem={getSelectedItem}
                  selectedItem={selectedItem}
                  isClient={true}
                />
              ))}
            </ComboboxOptions>
          </FloatingPortal>
        )}
      </div>
      {hasError && (
        <span className="text-sm text-error" role="alert">
          {errorMessage}
        </span>
      )}
    </Combobox>
  )
}
